import React from 'react'
import Banner from '../components/Common/Banner'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const Terms = () => (
    <React.Fragment>
        <Seo title="Terms Of Use"/>
        <Layout>
           <Banner title="Content" subtitle="Coming Soon" />
        </Layout>
    </React.Fragment>
)

export default Terms